<template>
	<div class="recovery" id="constellation-net">
		<div class="form">
			<form @submit.prevent="recovery">
			<div class="logo">
				<div class="d-flex justify-content-center">
					<img src="@/apps/constelacion/assets/img/logobpbverde.png" alt="Bien para Bien">
				</div>
			</div>
			<div class="title text-center">
				<h1>Recuperar contraseña</h1>
			</div>
			<div class="form-body">
				<div class="form-group">
					<small><label for="usuario">Usuario</label></small>
					<input v-model="usuario" type="usuario" name="usuario" id="usuario" class="form-control">
				</div>
				<button type="submit" class="btn principal-btn">Recuperar</button>
				<div class="text-center">
					<router-link :to="{name: 'login'}" class="link-underline" >Cancelar</router-link>
				</div>
			</div>
		</form>

		<Modal v-if="success!=0" :options="{width: '40vw',close:true}" @close="success=0">
			<div class="title">Recuperar contraseña</div>
			<div class="body">
				<div v-if="success==1" class="row">
					<div class="col-sm-12">Se a enviado un correo con el link para recuperar tu contraseña, por favor, verifica tu buzón</div>
				</div>
				<div v-else class="row">
					<div class="col-sm-12">No se a podido enviar el correo electrónico, por favor, contacte a soporte.</div>
				</div>
			</div>
		</Modal>
		</div>
		<vue-particles color="#3DADF2"
			:particleOpacity="0.7"
			:particlesNumber="76"
			shapeType="circle"
			:particleSize="5"
			linesColor="#3DADF2"
			:linesWidth="2"
			:lineLinked="true"
			:lineOpacity="1"
			:linesDistance="150"
			:moveSpeed="4"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push">
		</vue-particles>
	</div>
</template>

<script type="text/javascript">
	import api from '@/api/account'
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		}
		,data: () => ({
			usuario: null
			,success: 0
		})
		,methods: {
			recovery: function() {
				this.$log.info('enviar');
				let payload = {
					usuario: this.usuario
					,url: encodeURI(process.env.VUE_APP_CONSTELACION)+'/recovery/password'
					,vista: 'base'
				};
				
				api.recuperar_password(payload)
				.then(res => {
					this.$log.info('res', res);
					this.success = 1;
				})
				.catch(err => {
					this.$log.info('err', err);
					// this.$helper.showAxiosError(err,'Error');
					this.success = 2;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '@/general-styles/components/forms.scss';
@import '@/general-styles/components/particles.scss';

	.recovery {
		background: rgba(255,255,255,.69);
    padding: 2rem;
    position: relative;
		z-index: 1;
		.logo {
			z-index: 1;
			img {
				width: 180px;
				height: 180px;
  			margin-bottom: 1rem;
			}	
		}
		.form {
			width: 400px;
			.title {
				h1 {
					color: #fff !important;
				}
			}
			.principal-btn {
				margin: 1.5rem 0;
			}
		}
	}
</style>